import jwt from "jsonwebtoken";


export function parseJwt (data) {
	const token = data;
	var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function verifyJwt(data) {

	let decoded = parseJwt(data);
	if (!decoded) {
		
		localStorage.clear();
		return false;
	}
	if (decoded && Date.now() >= decoded.exp * 1000) {
		
		window.location.replace("/");
		localStorage.clear();
		return false;
	}
	
	return true;
}



export function decodeJwt(token = localStorage.getItem("token")) {
	let decoded = parseJwt(token);

	if (!decoded) {
		
		localStorage.clear();
		return false;
	}
	if (decoded && Date.now() >= decoded.exp * 1000) {
		localStorage.clear();
		// localStorage.removeItem("token");
		return false;
	}

	localStorage.setItem("businessName",decoded.businessName);
	return decoded;
	
}

export function checkJwt() {
	let token = localStorage.getItem("token");
	let decoded = parseJwt(token);

	if (!decoded) {
		localStorage.clear();

		 window.location.replace('/');
	}

	if (decoded && Date.now() >= decoded.exp * 1000) {
		localStorage.clear();
		window.location.replace("/");
	}
	return token;
}

export function verifyAdminJwt(data) {
	let decoded = parseJwt(data);

	if (!decoded) {
		localStorage.clear();
		return false;
	}
	if (decoded && Date.now() >= decoded.exp * 1000) {
		window.location.replace("/");
		localStorage.clear();
		return false;
	}
	return true;
}

export function checkAdminJwt() {
	let token = localStorage.getItem("token");
	let decoded = parseJwt(token);

	if (!decoded) {
		localStorage.clear();

		//  window.location.replace('#/signin');
	}

	if (decoded && Date.now() >= decoded.exp * 1000) {
		localStorage.clear();
		window.location.replace("/");
	}
	return token;
}
